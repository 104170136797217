<script lang="ts" setup>
  import * as contentful from 'contentful'
  import { accountLinks } from '@/constants/navigation'
  import type { TypeNavigationItemSkeleton } from '@/types/contentful'
  import { definedItems } from '@/utils/contentful'

  const props = defineProps<{
    isOpen: boolean
    isSignedIn: boolean | null
    navLinks: (contentful.Entry<TypeNavigationItemSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string> | undefined)[]
  }>()

  const emit = defineEmits<{
    (event: 'close'): void
  }>()

  const isMenuOpen = computed<boolean>(() => props.isOpen)
  usePreventBodyOverflow(isMenuOpen)
</script>

<template>
  <transition name="fade">
    <div v-show="isMenuOpen" class="c-nav-menu-mobile" role="group">
      <div class="mobile-nav-links">
        <template v-for="link in definedItems(props.navLinks)" :key="link.id">
          <CLink
            :aria-label="link.fields.title"
            :href="link.fields.href"
            :is-embedded-app-link="link.fields.target === 'App'"
            :target="link.fields.target"
            class="mobile-nav-link"
            role="menuitem"
            @click="emit('close')"
            @keydown.enter="emit('close')"
          >
            {{ link.fields.title }}
          </CLink>
        </template>
      </div>

      <div v-if="isSignedIn !== null" class="mobile-nav-relish" role="group">
        <client-only>
          <template v-if="isSignedIn">
            <CLink
              v-for="(item, i) in accountLinks.relish"
              :key="i"
              :aria-label="item.title"
              :href="item.href"
              :is-embedded-app-link="item.isEmbeddedAppLink"
              class="mobile-nav-link-relish"
              role="menuitem"
              @click="$emit('close')"
              @keydown.enter="$emit('close')"
            >
              {{ item.title }}
            </CLink>
          </template>

          <template v-else>
            <CLink
              v-for="(item, i) in accountLinks.guest"
              :key="i"
              :aria-label="item.title"
              :href="item.href"
              :is-embedded-app-link="item.isEmbeddedAppLink"
              class="mobile-nav-link-relish"
              role="menuitem"
              @click="$emit('close')"
              @keydown.enter="$emit('close')"
            >
              {{ item.title }}
            </CLink>
          </template>
        </client-only>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
  .c-nav-menu-mobile {
    z-index: 900;
    position: fixed;
    height: calc(100vh - var(--navbar-height));
    width: 100%;
    top: var(--navbar-height);
    left: 0;

    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 24px 16px;

    overscroll-behavior: contain;
    overflow-y: scroll;

    background-color: var(--color-secondary-paper-light);

    .mobile-nav-links {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      margin-bottom: 8px;

      .mobile-nav-link {
        @include typography-header-4;
        color: var(--color-base-black);

        &:hover {
          color: var(--color-text-accent) !important;
        }
      }
    }

    .mobile-nav-relish {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      padding: 24px 16px;

      border-radius: 8px;
      background-color: var(--color-secondary-red-dark);
      background-image: url('../../../assets/icons/grilld/background-hands.svg');
      background-repeat: no-repeat;
      background-position: right 16px bottom; // offset 16px from the right

      .mobile-nav-link-relish {
        @include typography-body-strong;
        color: var(--color-base-white);
        text-transform: capitalize;
        cursor: pointer;

        &:hover {
          color: var(--color-base-white) !important;
        }
      }
    }
  }

  /* Animations */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 150ms ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
